import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { useState } from 'react';
import FormDialog from 'app/views/material-kit/dialog/FormDialog';
import { addUserValue, createUserForm } from 'app/api/printNode';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

function FormCanvas({ forms, setPosition, printerId }) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputId, setInputId] = useState(0);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const canvasRef = useRef(null);
  const [canvasState, setCanvasState] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let x = 0;
  let y = 0;
  let w = 0;
  let h = 0;




  const drawPreviousSquare = (canvas, x, y, z, h, id) => {
    const rect = new fabric.Rect({
      left: x,
      top: y,
      fill: '#8e0693',
      width: z,
      height: h,
      selectable: false,
    });

    rect.set('dataId', id);

    // Add a click event listener to the rectangle
    rect.on('mousedown', (options) => {
      // const isDblClick = options.e.detail === 2; // Check for double-click
      // if (isDblClick) {

      const clickedRect = options.target;
      const rectId = clickedRect.get('dataId');
      console.log('Rectangle clicked! ID:', rectId);
      setInputId(rectId)
      setOpen(true)
      // Use rectId for further processing or actions
      // }
    });

    canvas.add(rect);

  };

  const changeRectToText = (rectId, text) => {
    console.log(canvasState);

    if (canvasState) {
      const objects = canvasState._objects; // Access the objects array directly

      objects.forEach((obj) => {
        if (obj.get('dataId') === rectId) {
          const newText = new fabric.Text(text, {
            left: obj.left,
            top: obj.top,
            fontSize: 20,
            // Other text properties as needed
          });
          // Add a click event listener to the rectangle
          newText.on('dblclick', (options) => {
            const clickedRect = options.target;
            const rectId = clickedRect.get('dataId');
            console.log('Rectangle clicked! ID:', rectId);
            setInputId(rectId)
            setOpen(true)
            // Use rectId for further processing or actions
          });
          newText.set('dataId', rectId);
          canvasState.remove(obj);
          canvasState.add(newText);
        }
      });
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log(forms.id);

    try {

      createUserForm({ form_id: forms.id }).then((res) => {
        const user_form_id = res.data.result.id;
        const entries = canvasState._objects;
        const objectCount = entries.length;

        entries.forEach((obj, index) => {
          if (obj.text !== undefined) {
            console.log(obj.dataId);
            const formData = {
              user_form_details_id: res.data.result.id,
              form_field_id: obj.dataId,
              value: obj.text
            };

            addUserValue(formData)
              .then((res) => {
                if (index === objectCount - 1) {
                  setTimeout(() => {
                    setLoading(false);
                    const url = `/generate-image/${user_form_id}/${printerId}`;
                    navigate(url);
                  }, 1000)
                }
              });
          }
        });


        // if (entries.length > 0) {
        //   entries.forEach((item, index) => {
        //     if (item[1].value != "" && item[1].value != undefined) {

        //       const formData = {
        //         user_form_details_id: res.data.result.id,
        //         form_field_id: item[1].id,
        //         value: item[1].value
        //       };

        //       addUserValue(formData)
        //         .then((res) => {
        //           if (index === numEntries - 1) {
        //             setTimeout(() => {
        //               const url = `/generate-image/${user_form_id}/${printerId}`;
        //               navigate(url);
        //             }, 1000)
        //           }
        //         });
        //     } else {
        //       if (index === numEntries - 1) {
        //         setTimeout(() => {
        //           const url = `/generate-image/${user_form_id}/${printerId}`;
        //           navigate(url);
        //         }, 1000)
        //       }
        //     }

        //   });
        // } else {
        //   const printData = {
        //     file_url: res.data.result.form.image,
        //     isColor: state.color != 'gray-scale',
        //     count: state.count,
        //     printer_id: printerId,
        //   };

        //   printFile(printData).then((response) => {
        //     // setIsOpen(true);
        //     // setLoading(false);
        //     const url = `/print-success/${printerId}`;
        //     navigate(url);
        //   })
        // }


      })
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  }


  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current,{
      allowTouchScrolling: true,
      enablePointerEvents: true
    });
    setCanvasState(canvas);
    console.log(forms.image);
    // Load the image
    fabric.Image.fromURL(forms.image, (img) => {
      console.log(img);
      // Set the image as the canvas background
      canvas.setHeight(img.height);
      canvas.setWidth(img.width)
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
        scaleX: canvas.width / img.width,
        scaleY: canvas.height / img.height,
      });
    });

    if (forms.hasOwnProperty('form_field')) {
      forms.form_field.map((item) => {
        console.log(item);
        drawPreviousSquare(canvas, parseInt(item.x), parseInt(item.y), parseInt(item.w), parseInt(item.h), item.id)
      })
    }

    return () => {
      // Clean up the canvas and set the ref to null on unmount
      canvas.dispose(); // Dispose the Fabric.js canvas instance
      canvasRef.current = null; // Set the ref to null
    };

  }, [forms]);


  return (
    <>
      <canvas
        ref={canvasRef}
      />

      <LoadingButton
        onClick={handleSubmit}
        color="primary"
        loading={loading}
        variant="contained"
        sx={{
          width: '40%',
          my: 2,
          // marginLeft: "25%",
          backgroundColor: "yellow",
          color: "black",
          "&:hover": {
            backgroundColor: "white", // Change background color on hover
            color: "black", // Change text color on hover
          },
        }}

      >
        Submit
      </LoadingButton>

      <FormDialog
        open={open}
        setOpen={setOpen}
        inputId={inputId}
        submitHandler={changeRectToText} // Pass the function as a prop

      />
    </>
  );
};

export default FormCanvas;
