import { Avatar, Box, Card, Grid, Paper, Typography, styled } from '@mui/material'
import { getActiveSubCategoryById } from 'app/api/printNode';
import { MatxLoading } from 'app/components';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

function Link() {

  const { subCategoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [formsOptions, setFormsOptions] = useState([]);
  const navigate = useNavigate();



  useState(() => {
    setLoading(true);
    getActiveSubCategoryById(subCategoryId).then((res) => {
      console.log(res.data.result.data);
      setForm(res.data.result.data.name);
      setFormsOptions(res.data.result.data.links);
      setLoading(false);
    });
    return () => {
      setFormsOptions({});
      setLoading(false);

    }
  }, []);

  const handleClick = (id) => {
    // console.log(id);
    // const url = `/fill-form/${id}/${printerId}`;
    // navigate(url);
  }


  return (
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        >
          
            <Box key={0}>
                <center>
                    <Typography
                        variant="h3"
                        sx={{
                            paddingBottom: '60px',
                            color: 'black'
                        }}
                    >
                        {form}
                    </Typography>
                </center>
            </Box>
            <Grid
                container
                item
                gap={'20px'}
                spacing={5}
                justifyContent="center"
                alignItems="stretch"
                sx={{
                    margin: '0',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                {(loading) ? <MatxLoading /> :
                    formsOptions.map((item) => {
                        // console.log(item)
                        return <Grid
                            item
                            sm={12} // On extra-small screens, take up the full width
                            md={4} 
                            sx={{
                                backgroundColor: '#fff',
                                padding: '0 !important',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'baseline',
                                justifyContent: 'space-between',
                                height: 350,
                                width: 350,
                                borderRadius: 5,
                                overflow: 'hidden',
                                "&:hover":{
                                    boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.2)',
                                    cursor: 'pointer',
                                }
                            // }} key={item.id} onClick={() => window.location.replace(item.link)}>
                            }} key={item.id} onClick={() => navigate(`/frame/${item.id}`)}>
                            <Avatar
                                variant="square"
                                alt={item.name}
                                src={item.thumbnail}
                                sx={{
                                    height: '80%',
                                    width: 'auto',
                                    maxWidth: '95%',
                                    borderRadius: 5,
                                    marginTop: 1,
                                    alignSelf: 'center',
                                }}
                            />
                            <Typography
                                style={{ color: 'black', fontWeight: 600, fontSize: 30, margin: 'auto', textAlign: 'center'}}
                                variant="p"
                            >
                                {item.name}
                            </Typography>
                        </Grid>
                    })
                }
            </Grid>
        </Grid>

  )
}

export default Link
