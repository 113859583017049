import { getLinkById } from 'app/api/printNode';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const Frame = () => {

    const { formId } = useParams();
    const [url, setUrl] = useState('');

    const handleIframeError = () => {
        alert('unable to load: '+url+' due to security reason, redirecting to website.');
        // Redirect to an error page or a default URL
        window.location.href = url; // Replace with your error page URL
    };

    useEffect(() => {
        getLinkById(formId).then((res) => {
            setUrl(res.data.result.data.link);
        })
    }, [])

    useEffect(() => {
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.style.display = 'none';

        document.body.appendChild(iframe);

        const checkIframeLoaded = setTimeout(() => {
            if (!iframe.contentDocument || !iframe.contentDocument.body) {
                console.error('Error loading iframe content');
                // Redirect to an error page or a default URL
                alert(`The content cannot be displayed for security reasons. You'll be redirected to the website.`)
                window.location.href = url; // Replace with your error page URL
            }
        }, 5000); // Adjust timeout as needed

        return () => {
            clearTimeout(checkIframeLoaded);
            document.body.removeChild(iframe);
        };
    }, [url]);


  return (
    <iframe
        title="External Website"
        src={url}
        width="100%"
        height="800px"
        // sandbox="allow-scripts allow-same-origin"
        frameBorder="0"
        style={{
            marginTop: '-32px'
        }}
        onError={handleIframeError}
    >
    </iframe>
  )
}

export default Frame
